import { fileSourceTypes } from '@/config/connection/spreadsheet.config';

export const spreadsheet = {
  data() {
    return {
      noUploadedLinks: false
    };
  },
  methods: {
    getFilesData() {
      return this.$refs.fileUpload.submitData();
    },
    async validateUploads() {
      const fileUpload = this.$refs.fileUpload;

      const { fileSource, files } = await this.getFilesData();

      if (fileSource === fileSourceTypes.FILE) {
        const dropzoneObserverValid = await fileUpload.$refs.dropzoneObserver.validate();
        const dropzoneValid = fileUpload.$refs.dropzone.validate();

        return dropzoneObserverValid && dropzoneValid;
      }

      const linksObserverValid = await fileUpload.$refs.linksObserver.validate();

      this.noUploadedLinks = !files.length;

      return !this.noUploadedLinks && linksObserverValid;
    }
  }
};