<template>
  <ConnectorContentBlock>
    <template #title>
      {{ $t('Web.Spreadsheet.TabMatchSheets') }}
    </template>
    <template #subtitle>
      {{ $t('Web.Spreadsheet.MatchSheetsSubtitle') }}
    </template>
    <div class="connector-content-additional">
      <div class="connector-content-additional__content">
        <ConnectorMatchBlock
          :left-label="$t('Web.Spreadsheet.MatchStreamlineSheet')"
          :middle-label="fileMatchLabel"
          :right-label="$t('Web.Spreadsheet.MatchTableSheet')"
        >
          <div
            v-for="tab in importTabs"
            :key="tab.value"
            class="connector-content-row"
          >
            <div class="w-150 body-1 grey-80">
              {{ tab.label }}
            </div>
            <SlSelect
              v-if="isMultipleFiles"
              :value="getFileValue(tab.value)"
              :placeholder="$t('Web.Spreadsheet.SelectFile')"
              :options="filesOptions"
              :is-invalid="invalidTabValue === tab.value"
              track-by="fileId"
              class="w-240"
              inline
              allow-empty
              @input="(value) => updateFileValue(tab.value, value)"
            />
            <SlSelect
              v-tooltip="getSheetTooltip(tab.value)"
              :value="getSheetValue(tab.value)"
              :placeholder="$t('Web.Spreadsheet.SelectSheet')"
              :options="getSheetOptions(tab.value)"
              :disabled="getIsSheetDisabled(tab.value)"
              :is-invalid="invalidTabValue === tab.value"
              :loading="isSheetsLoading"
              label="name"
              track-by="index"
              class="w-240"
              inline
              allow-empty
              @input="(value) => updateSheetValue(tab.value, value)"
            />
          </div>
        </ConnectorMatchBlock>
      </div>
    </div>
  </ConnectorContentBlock>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ConnectorContentBlock from '@/components/Connections/Common/ConnectorContentBlock';
import ConnectorMatchBlock from '@/components/Connections/Common/ConnectorMatchBlock';
import { importTabs, mainTabs, matchKeys, tabKeys } from '@/config/connection/spreadsheet.config';
import { fileFormats } from '@/config/utils/fileUpload.config';

export default {
  name: 'MatchSheets',
  components: {
    ConnectorMatchBlock,
    ConnectorContentBlock
  },
  inheritAttrs: false,
  props: {
    invalidTabValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isSheetsLoading: false
    };
  },
  inject: [
    'getTooltip',
    'setIsEdited',
    'resetTabValidation'
  ],
  computed: {
    ...mapState({
      dataType: (state) => state.spreadsheet.commonData.type,
      filesSheets: (state) => state.spreadsheet.files_sheets,
      files: (state) => state.spreadsheet.files,
      tabData: state => state.spreadsheet[tabKeys.MATCH_SHEETS]
    }),
    ...mapGetters({
      isMultipleFiles: 'spreadsheet/isMultipleFiles',
      fileByFileId: 'spreadsheet/fileByFileId'
    }),
    importTabs() {
      return importTabs(this, this.dataType);
    },
    filesOptions() {
      return this.files.map(file => ({
        fileId: file.fileId,
        label: file.name
      }));
    },
    firstFileId() {
      return this.filesOptions[0]?.fileId;
    },
    fileMatchLabel() {
      if (this.isMultipleFiles) {
        return this.$t('Web.Spreadsheet.MatchFile');
      }

      return '';
    }
  },
  created() {
    if (!this.isMultipleFiles && this.firstFileId) {
      this.loadSheetList(this.firstFileId);
    }
  },
  methods: {
    ...mapActions({
      updateMatchedSheet: 'spreadsheet/updateMatchedSheet',
      fetchSlots: 'spreadsheet/fetchSlots',
      fetchSheetList: 'spreadsheet/fetchSheetList',
      fetchWarnings: 'spreadsheet/fetchWarnings',
      resetWarnings: 'spreadsheet/resetWarnings'
    }),
    async loadSheetList(fileId) {
      try {
        this.isSheetsLoading = true;

        await this.fetchSheetList(fileId);
      } finally {
        this.isSheetsLoading = false;
      }
    },
    checkIsMatchedCsv(key) {
      const file = this.fileByFileId(this.getFileValue(key));

      return file && file.type === fileFormats.csv;
    },
    getSheetTooltip(key) {
      return this.getTooltip({
        content: this.$t('Web.Spreadsheet.TooltipCsvSheet'),
        disabled: !this.checkIsMatchedCsv(key)
      });
    },
    getFileValue(key) {
      return this.tabData[key][matchKeys.FILE];
    },
    getSheetOptions(key) {
      if (this.isMultipleFiles) {
        return this.filesSheets[this.getFileValue(key)] || [];
      }

      return this.filesSheets[this.firstFileId] || [];
    },
    getSheetValue(key) {
      return this.tabData[key][matchKeys.SHEET];
    },
    getIsSheetDisabled(key) {
      if (this.checkIsMatchedCsv(key)) {
        return true;
      }

      if (this.isMultipleFiles) {
        return !this.getFileValue(key);
      }

      return false;
    },
    updateFileValue(key, value) {
      this.updateMatchedSheet({
        key,
        field: matchKeys.FILE,
        value
      });

      if (mainTabs.includes(key)) {
        this.resetWarnings();
      }

      if (value) {
        this.loadSheetList(value);
      }
    },
    updateSheetValue(key, value) {
      this.updateMatchedSheet({
        key,
        field: matchKeys.SHEET,
        value
      });

      this.fetchSlots(key);

      if (mainTabs.includes(key)) {
        this.fetchWarnings();
      }

      this.setIsEdited();

      if (key === this.invalidTabValue) {
        this.resetTabValidation();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.connector-content-block::v-deep {
  .connector-content-block__main {
    height: auto !important;
    max-height: initial !important;
    padding-bottom: 24px;

    .connector-content-row {
      display: flex;
      align-items: center;
    }
  }
}
</style>
