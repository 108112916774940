<template>
  <div class="tab-navigation">
    <div
      v-if="prevTab"
      class="tab-navigation__prev"
    >
      <div class="tab-navigation__prev-text body-3 grey-60">
        {{ $t('Web.Spreadsheet.BtnPrevTab') }}
      </div>
      <SlButton
        variant="text"
        color="grey"
        @click="handlePrevTab"
      >
        <template #prepend>
          <icon
            data="@icons/arrow_left_long.svg"
            class="fill-off size-16"
          />
        </template>
        {{ prevTab.label }}
      </SlButton>
    </div>
    <div
      v-if="nextTab"
      class="tab-navigation__next"
    >
      <div class="tab-navigation__next-text body-3 grey-60">
        {{ $t('Web.Spreadsheet.BtnNextTab') }}
      </div>
      <SlButton
        variant="text"
        color="grey"
        @click="handleNextTab"
      >
        <template #append>
          <icon
            data="@icons/arrow_right_long.svg"
            class="fill-off size-16"
          />
        </template>
        {{ nextTab.label }}
      </SlButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConnectorTabsNavigation',
  props: {
    tabs: {
      type: Array,
      required: true
    },
    currentTab: {
      type: String,
      required: true
    }
  },
  computed: {
    currentTabIndex() {
      return this.tabs.findIndex(tab => tab.value === this.currentTab);
    },
    nextTab() {
      return this.tabs[this.currentTabIndex + 1];
    },
    prevTab() {
      return this.tabs[this.currentTabIndex - 1];
    }
  },
  methods: {
    handlePrevTab() {
      this.$emit('tab-switch', this.prevTab.value);
    },
    handleNextTab() {
      this.$emit('tab-switch', this.nextTab.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.tab-navigation {
  position: relative;

  .tab-navigation__prev {
    position: absolute;
    top: 0;
    left: 0;

    .tab-navigation__prev-text {
      padding-left: 30px;
    }
  }

  .tab-navigation__next {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>