<template>
  <SlModal
    :id="id"
    :title="$t('Web.File.UploadFile')"
    :persistent="isUploading"
    :close-callback="handleCancel"
    @created="onCreated"
    @hide="onModalHide"
    @on-enter="handleSave"
  >
    <div class="modal-content spreadsheet-connector">
      <FilesUpload
        ref="fileUpload"
        :link-alert="noUploadedLinks"
        @link-upload-success="noUploadedLinks = false"
        @loading="toggleUploading"
      />
    </div>

    <template #footer>
      <SlModalFooter between>
        <div class="sl-modal__footer-group">
          <SlButton
            variant="secondary"
            color="grey"
            @click="handleCancel"
          >
            {{ $t('Common.Cancel') }}
          </SlButton>
          <SlButton
            :disabled="isUploading"
            @click="handleSave"
          >
            {{ $t('Web.Modals.BtnSave') }}
          </SlButton>
        </div>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FilesUpload from '@/components/Connections/Spreadsheet/FilesUpload';
import { modal } from '@/mixins/modal';
import { spreadsheet } from '@/mixins/connection/spreadsheet.mixin';

export default {
  name: 'SpreadsheetUploadModal',
  components: {
    FilesUpload
  },
  mixins: [modal, spreadsheet],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isUploading: false,
      callback: null
    };
  },
  computed: {
    ...mapState({
      filesSource: state => state.spreadsheet.files_source,
      files: state => state.spreadsheet.files
    })
  },
  methods: {
    ...mapActions({
      setFilesSource: 'spreadsheet/setFilesSource',
      setFiles: 'spreadsheet/setFiles'
    }),
    handleCancel() {
      this.noUploadedLinks = false;

      this.hideModal(this.id);
    },
    toggleUploading(value) {
      this.isUploading = value;
    },
    onModalHide() {
      this.callback = null;
    },
    async handleSave() {
      const valid = await this.validateUploads();

      if (!valid) {
        return;
      }

      const { fileSource, files } = await this.getFilesData();

      this.setFiles([
        ...this.files,
        ...files
      ]);
      this.setFilesSource(fileSource);

      this.callback && this.callback();

      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/connection/connectors/spreadsheet/spreadsheet-connector-modal.scss";
</style>