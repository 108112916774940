import { fileTypeFromName } from '@/helpers/utils/fileUpload';

export default function(data, id = null) {
  if (!data) {
    return null;
  }

  const {
    id: fileId,
    sz: fileSize,
    fileName
  } = data;

  return {
    ...(id && { id }),
    fileId,
    name: fileName,
    size: fileSize,
    type: fileTypeFromName(fileName)
  };
}
